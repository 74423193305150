import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import config from "./config.js";
import { fetchData } from "./libs/fetchData";
import useToken from './components/useToken'

import Login from "./pages/Login";
import LoginByUrlToken from "./pages/LoginByUrlToken";
import Home from "./pages/Home";
import ReplaceWords from "./pages/ReplaceWords";
import Prices from "./pages/Prices";
import Statistics from "./pages/Statistics";
import Terms from "./pages/Terms";
import Studio from "./pages/Studio";
import Loading from "./pages/Loading";
import NoPage from "./pages/NoPage";

function App() {
	const { token, removeToken, setToken } = useToken();
	useEffect(() => {
		if (token) {
			const { exp } = JSON.parse(atob(token.split('.')[1]));
			if (exp * 1000 <= Date.now()) {
				removeToken();
			}
		}
	}, [token, removeToken]);

	const [userData, setUserData] = useState(null);

	useEffect(() => {
		fetchData(token, setToken, setUserData, config.apiBaseUrl + "/profile");
	}, [token]);

	return (
		<BrowserRouter>
			{!token && token !== "" && token !== undefined ?
				<Routes>
					<Route index path="login-by-url-token" element={<LoginByUrlToken token={token} setToken={setToken} />} />
					<Route path="*" element={<Login setToken={setToken} />} />
				</Routes>
			: (
				<>
					{ userData ? (
						<Routes>
							<Route index element={<Home token={token} setToken={setToken} userData={userData} />} />
							<Route path="replace-words" element={<ReplaceWords token={token} setToken={setToken} userData={userData} />} />
							<Route path="terms" element={<Terms token={token} setToken={setToken} userData={userData} />} />
							<Route path="prices" element={<Prices token={token} setToken={setToken} userData={userData} />} />
							<Route path="statistics" element={<Statistics token={token} setToken={setToken} userData={userData} />} />
							<Route path="studio/:id" element={<Studio token={token} setToken={setToken} userData={userData} />} />
							<Route path="login-by-url-token" element={<LoginByUrlToken token={token} setToken={setToken} />} />
							<Route path="*" element={<NoPage />} />
						</Routes>
					) : (
						<Loading />
					)}
				</>
			)}
		</BrowserRouter>
	);
}

export default App;

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
