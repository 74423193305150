import { Translate } from 'react-i18nify';
import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { fetchData } from "./../libs/fetchData";
import axios from 'axios';
import config from "../config.js";
import Header from '../components/Header'
import LoggedInUserInfo from '../components/LoggedInUserInfo'
import VideoList from '../components/VideoList'
import SimpleFooter from '../components/SimpleFooter'
import useToken from '../components/useToken'

const HomeStyle = styled.div`
	max-width: 800px;
	margin: auto;

	input[type="file"] {
		display: none;
	}

	.custom-file-upload {
		display: inline-block;
		padding: 10px 20px;
		background: #727972;
		color: #fff;
		cursor: pointer;
		min-width: 250px;
		text-align: center;
		border-radius: 4px;
		-webkit-transition: background 0.3s;
		transition: background 0.3s;
	}

	.custom-file-upload:hover {
		background: #868d86;
	}
`

function Home(props) {
	const [videoData, setVideoData] = useState(null);
	const [videoUploaded, setVideoUploaded] = useState(false);
	const { token } = useToken();

	useEffect(() => {
		fetchData(props.token, props.setToken, setVideoData, config.apiBaseUrl + "/video-list");
	}, [props.token, props.setToken]);

	useEffect(() => {
		if (videoUploaded) {
			fetchData(props.token, props.setToken, setVideoData, config.apiBaseUrl + "/video-list");
			setVideoUploaded(false);
		}
	}, [videoUploaded, props]);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		formData.append("file", file);

		const headers = { Authorization: `Bearer ` + props.token };
		axios.post(config.apiBaseUrl + "/upload", formData, { headers })
			.then((response) => {
				setVideoUploaded(true)
			})
			.catch((error) => {
				console.error("Failed to upload file:", error);
			});
	};

	return (
		<>
			<Header token={token} userData={props.userData} />
			<HomeStyle>
				<h1><Translate value="WELCOME" /></h1>
				{videoData ? (
					<>
						<LoggedInUserInfo userData={props.userData} /><br />
						<VideoList videoData={videoData} />
						<label htmlFor="file-upload" className="custom-file-upload">
							Velg fil
						</label>
						<input id="file-upload" type="file" accept="video/mp4,video/x-m4v,video/*,audio/x-m4a,audio/*" key={videoUploaded ? 'fileUploaded' : 'fileNotUploaded'} onChange={handleFileChange} />
					</>
				) : (
					<p>Laster...</p>
				)}
			</HomeStyle>
			<SimpleFooter />
		</>
	);
};

export default Home;
