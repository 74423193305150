import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useToken from '../components/useToken'

const Style = styled.div`
	.top-menu {
		background-color: #f2f2f2;
		padding: 15px;

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: flex;

			li {
				display: flex;
				align-items: center;
				margin-right: 10px;
				height: 50px;

				img {
					height: 50px;
				}

				a {
					text-decoration: none;
					color: #333;
					padding: 5px 10px;
					border-radius: 4px;
					transition: background-color 0.3s ease;
				}
				a:hover {
					background-color: #ddd;
				}
			}
			li.logo {
				margin-right: 40px;
			}
		}
	}
`;

function Header(props) {
	const { removeToken } = useToken();

	function logMeOut() {
		removeToken();
		window.location.reload();
	}

	return (
		<Style>
			<nav className="top-menu">
				<ul>
					<li className="logo">
						<Link to={`../`}>
							<img src="/logo.png" alt="Logo" /> Supertekst
						</Link>
					</li>
					{props.userData && (
						<>
							<li>
								<Link to={`../`}>Hjem</Link>
							</li>
							<li>
								<Link to={`../replace-words`}>Ordlister</Link>
							</li>
							<li>
								<Link to={`../terms`}>Vilkår</Link>
							</li>
							{props.userData.type === "admin" && (
								<>
									<li>
										<Link to={`../prices`}>Priser</Link>
									</li>
									<li>
										<Link to={`../statistics`}>Statistikk</Link>
									</li>
								</>
							)}
							<li onClick={logMeOut}>
								<Link to={`../`}>Logg ut</Link>
							</li>
						</>
					)}
				</ul>
			</nav>
		</Style>
	);
}

export default Header;
