
function LoggedInUserInfo(props) {
	var userData = props.userData;

	return (
		<span>
			Du er logget inn som {userData.first_name} {userData.last_name}, på vegne av {userData.company_name} (org. nr: {userData.org_number}).
		</span>
	);
}

export default LoggedInUserInfo;
