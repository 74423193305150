import { useEffect } from "react";
import useToken from '../components/useToken'

function LoginByUrlToken() {
	const { setToken } = useToken();

	const extractTokenFromURL = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const tokenFromUrl = urlParams.get('token');
		const studioIdFromUrl = urlParams.get('studio_id');
		const videoPathFromUrl = urlParams.get('video_path');
		if (tokenFromUrl) {
			setToken(tokenFromUrl);
		}
		if (studioIdFromUrl && tokenFromUrl) {
			// Ideelt sett burde vi brukt navigate(), men har hatt problemer
			// med at den forsøker å bruke gammel token, slik at man blir sendt
			// til innlogging i stedet. Ved å bruke window.location er vi sikre
			// på at ny token brukes
			window.location.href = '/studio/' + studioIdFromUrl + '/?video_path=' + videoPathFromUrl;
		}
	};

	useEffect(() => {
		extractTokenFromURL();
	}, []);
};

export default LoginByUrlToken;
