import React, { useState } from "react";
import axios from "axios";
import { Translate } from 'react-i18nify';
import styled from 'styled-components';
import config from '../config.js';

const LoginStyle = styled.div`
	@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: auto;

	h1 {
		font-weight: bold;
		margin: 0;
	}

	h2 {
		text-align: center;
	}

	p {
		font-size: 14px;
		font-weight: 100;
		line-height: 20px;
		letter-spacing: 0.5px;
		margin: 20px 0 30px;
	}

	span {
		font-size: 12px;
	}

	a {
		color: #333;
		font-size: 14px;
		text-decoration: none;
		margin: 15px 0;
	}

	button {
		border-radius: 20px;
		border: 1px solid #FF4B2B;
		background-color: #FF4B2B;
		color: #FFFFFF;
		font-size: 12px;
		font-weight: bold;
		padding: 12px 45px;
		letter-spacing: 1px;
		text-transform: uppercase;
		transition: transform 80ms ease-in;
	}

	button:active {
		transform: scale(0.95);
	}

	button:focus {
		outline: none;
	}

	form {
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 50px;
		height: 100%;
		text-align: center;
	}

	input {
		background-color: #eee;
		border: none;
		padding: 12px 15px;
		margin: 8px 0;
		width: 100%;
	}

	.container {
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0 14px 28px rgba(0,0,0,0.25),
				0 10px 10px rgba(0,0,0,0.22);
		position: relative;
		overflow: hidden;
		width: 768px;
		max-width: 100%;
		min-height: 480px;
	}

	.form-container {
		position: absolute;
		top: 0;
		height: 100%;
		transition: all 0.6s ease-in-out;
	}

	.sign-in-container {
		left: 0;
		width: 50%;
		z-index: 2;
	}

	.container.right-panel-active .sign-in-container {
		transform: translateX(100%);
	}

	.overlay-container {
		position: absolute;
		top: 0;
		left: 50%;
		width: 50%;
		height: 100%;
		overflow: hidden;
		transition: transform 0.6s ease-in-out;
		z-index: 100;
	}

	.container.right-panel-active .overlay-container{
		transform: translateX(-100%);
	}

	.overlay {
		background: #FF416C;
		background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
		background: linear-gradient(to right, #FF4B2B, #FF416C);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0 0;
		color: #FFFFFF;
		position: relative;
		left: -100%;
		height: 100%;
		width: 200%;
		transform: translateX(0);
		transition: transform 0.6s ease-in-out;
	}

	.container.right-panel-active .overlay {
		transform: translateX(50%);
	}

	.overlay-panel {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 40px;
		text-align: center;
		top: 0;
		height: 100%;
		width: 50%;
		transform: translateX(0);
		transition: transform 0.6s ease-in-out;
	}

	.overlay-left {
		transform: translateX(-20%);
	}

	.container.right-panel-active .overlay-left {
		transform: translateX(0);
	}

	.overlay-right {
		right: 0;
		transform: translateX(0);
	}

	.overlay-right h1 {
		color: white;
	}

	.container.right-panel-active .overlay-right {
		transform: translateX(20%);
	}

	footer {
		background-color: #222;
		color: #fff;
		font-size: 14px;
		bottom: 0;
		position: fixed;
		left: 0;
		right: 0;
		text-align: center;
		z-index: 999;
	}

	footer p {
		margin: 10px 0;
	}

	footer i {
		color: red;
	}

	footer a {
		color: #3c97bf;
		text-decoration: none;
	}

	.shake {
		animation: shake 0.4s ease-in-out 2;
	}

	@keyframes shake {
		0% {
			transform: translateX(0);
		}
		20% {
			transform: translateX(-10px);
		}
		40% {
			transform: translateX(10px);
		}
		60% {
			transform: translateX(-10px);
		}
		80% {
			transform: translateX(10px);
		}
		100% {
			transform: translateX(0);
		}
	}

	@media only screen and (max-width: 1000px) {
		.container {
			display: flex;
		}
		.sign-in-container {
			width: 100%;
		}
		.overlay-container {
			display: none;
		}
	}
`;

function Login(props) {
	const [showErrorAnimation, setShowErrorAnimation] = useState(false);

	const [loginForm, setloginForm] = useState({
		email: "",
		password: ""
	  })

	function logMeIn(event) {
		axios({
			method: "POST",
			url: config.apiBaseUrl + "/token",
			data:
			{
				email: loginForm.email,
				password: loginForm.password
			}
		})
		.then((response) => {
			if (response.data.access_token)
			{
				props.setToken(response.data.access_token)
			}
		}).catch((error) => {
			if (error.response) {
				console.log(error.response)
				console.log(error.response.status)
				console.log(error.response.headers)
				setShowErrorAnimation(true);
				setTimeout(() => setShowErrorAnimation(false), 1000);
			}
		})
		setloginForm(({
			email: "",
			password: ""
		}))
		event.preventDefault()
	  }

	function handleChange(event) {
		const {value, name} = event.target
		setloginForm(prevNote => ({
			...prevNote, [name]: value})
	)}

	return (
		<>
			<LoginStyle>
				<div className="container" id="container">
					<div className="form-container sign-in-container">
					<form>
						<h1>Logg inn</h1>
						<input onChange={handleChange}
							type="email"
							text={loginForm.email}
							placeholder="E-post"
							name="email"
							value={loginForm.email}

						/>
						<input onChange={handleChange}
							type="password"
							placeholder="Passord"
							className={showErrorAnimation ? "shake" : ""}
							name="password"
							value={loginForm.password}
						/>
						<a href="./?forgot-password">Glemt passord?</a>
						<button onClick={logMeIn}>Logg inn</button>
						</form>
					</div>
					<div className="overlay-container">
						<div className="overlay">
							<div className="overlay-panel overlay-right">
								<h1><Translate value="WELCOME" /></h1>
							</div>
						</div>
					</div>
				</div>
				<footer>
					<p>
						Supertekst &copy; 2023
					</p>
				</footer>
			</LoginStyle>
		</>
	);
};

export default Login;
