import { Translate } from 'react-i18nify';
import Header from '../components/Header'
import InfoPage from '../components/InfoPage'
import LoggedInUserInfo from '../components/LoggedInUserInfo'
import Loading from '../components/Loading'
import SimpleFooter from '../components/SimpleFooter'
import useToken from '../components/useToken'

function Terms(props) {
	const { token } = useToken();

	return (
		<>
			<Header token={token} userData={props.userData} />
			{ props.userData ? (
				<InfoPage>
					<p id="user_info">
						<LoggedInUserInfo userData={props.userData} /><br />
						Vilkårene er sist oppdatert 31. august 2023.
					</p>
					<h1><Translate value="TERMS.TITLE" /></h1>
					<div className="terms_container table_of_contents">
						<h2>Innhold</h2>
						<ul>
							<li><a href="#terms1">1. Vilkår for bruk</a></li>
							<li><a href="#terms2">2. Opphavsrett, ulovlig innhold og sanksjoner</a></li>
							<li><a href="#terms3">3. Tilgjengelighetsgaranti</a></li>
							<li><a href="#terms4">4. Lagring av data</a></li>
							<li><a href="#terms5">5. Oversettelse av undertekster</a></li>
							<li><a href="#terms6">6. Rett til innsyn i lagrede data</a></li>
							<li><a href="#terms7">7. Rett til sletting av data</a></li>
							<li><a href="#terms8">8. Bruksrett til metadata</a></li>
							<li><a href="#terms9">9. Inaktivitet og sletting av konto</a></li>
							<li><a href="#terms10">10. Kredittvurdering</a></li>
						</ul>
					</div>
					<div className="terms_container">
						<a href="#/" name="terms1"> </a>
						<h2>1. Vilkår for bruk</h2>
						<p>Ved å bruke Supertekst transkriberingstjeneste (heretter kalt "Tjenesten"), godtar du følgende vilkår for opplasting av videofiler, lydfiler og lignende (heretter kalt "Innhold"). Du gir oss tillatelse til å behandle dine data i samsvar med disse retningslinjene.</p>
					</div>
					<div className="terms_container">
						<a href="#/" name="terms2"> </a>
						<h2>2. Opphavsrett, ulovlig innhold og sanksjoner</h2>
						<p>Du forsikrer at du har alle nødvendige rettigheter, inkludert opphavsrett, til innholdet du laster opp til Tjenesten. Dette betyr at du enten er eier av innholdet, eller at du har fått nødvendig tillatelse fra eieren til å laste opp og distribuere innholdet via Tjenesten.</p>
						<p>Du skal ikke laste opp, distribuere eller publisere innhold som:</p>
						<ul>
							<li>Er ulovlig, truende, ærekrenkende, injurierende, invaderende av personvern, eller som på annen måte krenker andres rettigheter.</li>
							<li>Fremmer ulovlige aktiviteter, hat, diskriminering, vold eller skade mot enkeltpersoner eller grupper.</li>
							<li>Inneholder støtende, obskønt eller pornografisk materiale.</li>
							<li>Krenker intellektuelle eiendomsrettigheter, inkludert opphavsrett, varemerkerett, patentrett eller andre proprietære rettigheter.</li>
						</ul>
						<p>Du forstår og aksepterer at du er eneansvarlig for innholdet du laster opp og de potensielle konsekvensene av publiseringen av slikt innhold. Supertekst tar ikke ansvar for innholdet du laster opp og gir ingen garantier om nøyaktighet, kvalitet eller integritet av noe opplastet innhold.</p>
						<p>Dersom det oppdages at du bryter noen av disse vilkårene, forbeholder Supertekst seg retten til å fjerne det aktuelle innholdet og/eller suspendere eller avslutte din konto uten forvarsel.</p>
					</div>
					<div className="terms_container">
						<a href="#/" name="terms3"> </a>
						<h2>3. Tilgjengelighetsgaranti</h2>
						<p>Supertekst streber kontinuerlig etter å tilby en pålitelig og stabil transkriberingtjeneste for våre brukere. Selv om vi gjør vårt ytterste for å sikre kontinuerlig drift og tilgjengelighet, kan vi ikke garantere uavbrutt eller feilfri tilgang til Tjenesten til enhver tid.</p>
						<p>Teknisk vedlikehold, oppdateringer, eksterne faktorer, eller hendelser utenfor vår kontroll kan påvirke tilgjengeligheten av Tjenesten. Vi er dedikerte til å adressere og løse eventuelle driftsforstyrrelser så raskt som mulig, men kan ikke under noen omstendigheter holdes ansvarlige for eventuelle avbrudd eller nedetid.</p>
						<p>Vi anbefaler at du regelmessig sikkerhetskopierer ditt innhold.</p>
					</div>
					<div className="terms_container">
						<a href="#/" name="terms4"> </a>
						<h2>4. Lagring av data</h2>
						<p>Med mindre annet eksplisitt er angitt, lagres alle data på servere lokalisert i Norge.</p>
						<p>Ved bruk av oversettelsestjenester benytter vi teknologi fra OpenAI Inc. OpenAI Inc bruker skytjenester levert av en ekstern leverandør, og derfor kan prosessering relatert til oversettelse involvere interaksjoner med eksterne systemer i utlandet.</p>
					</div>
					<div className="terms_container">
						<a href="#/" name="terms5"> </a>
						<h2>5. Oversettelse av undertekster</h2>
						<p>Ved bruk av oversettelsestjenester behandles dataene i datasentre i utlandet. Du må selv oppgi en OpenAI API-nøkkel for å bruke denne tjenesten.</p>
					</div>
					<div className="terms_container">
						<a href="#/" name="terms6"> </a>
						<h2>6. Rett til innsyn i lagrede data</h2>
						<p>Kunden har til enhver tid rett til å be om innsyn i alle data og filer som Supertekst har lagret i forbindelse med bruk av våre tjenester. Vi forplikter oss til å gi deg en fullstendig oversikt over dine lagrede data på en klar og forståelig måte innenfor en rimelig tidsramme.</p>
					</div>
					<div className="terms_container">
						<a href="#/" name="terms7"> </a>
						<h2>7. Rett til sletting av data</h2>
						<p>Kunden kan, når som helst, be om at kontoen, alle opplastede data (videofiler, lydfiler etc.) og genererte data (undertekster, dokumenter etc.) slettes fra våre systemer. Etter mottak av en slik forespørsel vil vi fjerne alle kundens data, inkludert opplastede filer, fra våre servere. Vær oppmerksom på at sletting er endelig, og data kan ikke gjenopprettes etter at sletteprosessen er gjennomført. Vi forbeholder oss retten til å lagre metadata om opplastede filer frem til fakturering er foretatt.</p>
					</div>
					<div className="terms_container">
						<a href="#/" name="terms8"> </a>
						<h2>8. Bruksrett til metadata</h2>
						<p>Vi benytter kundens data for å kontinuerlig forbedre og optimalisere systemet som brukes for å avgjøre hvilke ord som er sagt. Ved å bruke våre tjenester gir du tillatelse til å analysere og bruke dataene i denne forbedringsprosessen.</p>
						<p>Ved å bruke Supertekst gir du oss en en livsvarig, ubegrenset, ikke-eksklusiv bruksrett til dataene du laster opp eller genererer gjennom bruk av våre tjenester. Dette innebærer at vi kan beholde og bruke dataene for analytiske og forbedringsformål, selv etter at en kunde har avsluttet sitt kundeforhold med oss.</p>
						<p>Kunden kan når som helst velge å anonymisere sine data, slik at de ikke lenger kan knyttes direkte til den enkelte bruker. Supertekst beholder imidlertid metadata og analyserte innsikter (som for eksempel hvilke ord som er feilstavet) fra disse dataene. Dette er kritisk for systemets funksjonalitet, da slike metadata utgjør en integrert del av systemet.</p>
					</div>
					<div className="terms_container">
						<a href="#/" name="terms9"> </a>
						<h2>9. Inaktivitet og sletting av konto</h2>
						<p>Vi forbeholder oss retten til å slette brukerkontoer som ikke har vært aktive eller i bruk i løpet av de siste tre månedene. Kunder med kontoer som er i fare for sletting grunnet inaktivitet vil motta en påminnelse via e-post før slettehandling blir foretatt. Etter sletting vil alle data, inkludert opplastede filer, være permanent borte og kan ikke gjenopprettes.</p>
					</div>
					<div className="terms_container">
						<a href="#/" name="terms10"> </a>
						<h2>10. Kredittvurdering</h2>
						<p>Vi forbeholder oss retten til å kredittvurdere alle kunder som benytter seg av våre tjenester. Dersom en kundes kredittvurdering ikke tilfredsstiller våre fastsatte krav, kan vi, etter eget skjønn, nekte å tilby våre tjenester til vedkommende. Ved å bruke våre tjenester aksepterer du denne betingelsen og gir ditt samtykke til en slik vurdering.</p>
					</div>
				</InfoPage>
			) : (
				<Loading />
			)}
			<SimpleFooter />
		</>
	);
};

export default Terms;
