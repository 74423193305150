import React, { useState, useEffect } from 'react';
import { fetchData } from "./../libs/fetchData";
import { postData } from "../libs/postData";
import config from "../config.js";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

function WordListTable(props) {
	const [data, setData] = useState(null);
	const [dictionaries, setDictionaries] = useState(null);
	const [accordionExpanded, setAccordionExpanded] = React.useState(false);

	useEffect(() => {
		let endpoint;
		if (props.type === "ready_for_review")
		{
			endpoint = "/front-end/word-list/files-ready-for-review/";
		}
		else
		{
			endpoint = "/front-end/word-list/grouped-by-category/";
		}
		fetchData(props.token, props.setToken, setData, config.apiBaseUrl + endpoint);
	}, [props]);

	useEffect(() => {
		if (props.type === "ready_for_review")
		{
			var endpoint = "/front-end/word-list/"
			fetchData(props.token, props.setToken, setDictionaries, config.apiBaseUrl + endpoint);	
		}
	}, [props.token, props.setToken]);

	const handleSelectChange = async (file_id, dictionary_id, before, after) => {
		let endpoint = '/front-end/subtitle/changes/' + file_id;
		let values = {
			dictionary_id: dictionary_id,
			file_id: file_id,
			before: before,
			after: after
		};
		postData(props.token, endpoint, values);
	};

	const reviewWordDictionaryForFileCompleted = (file_id) => {
		let endpoint = '/generator/video-status/';
		let values = {
			id: file_id,
			status: "completed"
		};
		postData(props.token, endpoint, values, afterReviewWordDictionaryForFileCompleted);
	}

	const afterReviewWordDictionaryForFileCompleted = () => {
		window.location.reload();
	};

	const handleAccordionClick = (panel) => (event, isExpanded) => {
		setAccordionExpanded(isExpanded ? panel : false);
	};

	const renderTableData = () => {
		if (data === null) {
			return null;
		}
		if (props.type === "ready_for_review" && dictionaries === null) {
			return null;
		}
		if (data.length === 0) {
			return <div><i>Du har ingen filer som venter på gjennomgang.</i></div>;
		}
		return Object.keys(data).map((key) => {
			const dictionary = data[key];
			const accordionKey = 'accordion' + key;
			return (
				<Accordion key={accordionKey} expanded={accordionExpanded === accordionKey} onChange={handleAccordionClick(accordionKey)}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							{dictionary.title} ({dictionary.items.length} ord)
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{dictionary.items.length > 0 ? (
							<table className="table table-striped table-responsive">
								<thead>
									<tr>
										<th>Fra</th>
										<th>Til</th>
										{ props.type === "ready_for_review" && 
											<th>Ordliste</th>
										}
									</tr>
								</thead>
								<tbody>
									{dictionary.items.map((item, index) => (
										<tr key={`${key}-${index}`}>
											<td>{item.change_from}</td>
											<td>{item.change_to}</td>
											{ props.type === "ready_for_review" && 
												<td>
													<select defaultValue={item.dictionary_id} onChange={(e) => handleSelectChange(dictionary.id, e.target.value, item.change_from, item.change_to)}>
														<option value="">Ingen</option>
														{dictionaries && dictionaries.map((dictionary) => (
															<option 
																key={dictionary.id} 
																value={dictionary.id}
															>
																{dictionary.title}
															</option>
														))}
													</select>
												</td>
											}
										</tr>
									))}
								</tbody>
							</table>
							) : (
								<div>Ingen endrede ord ble funnet.</div>
							)
						}
						<Button variant="contained" onClick={() => reviewWordDictionaryForFileCompleted(dictionary.id)}>Lagre</Button>
					</AccordionDetails>
				</Accordion>
			);
		});
	};

	return (
		<>
			{data !== null ? (
				<div>
					{renderTableData()}
				</div>
			) : (
				<p>Laster...</p>
			)}
		</>
	);
};

export default WordListTable;
