import axios from "axios";

export const fetchData = async (token, setToken, setData, endpoint) => {
	try {
		const response = await axios({
			method: "GET",
			url: endpoint,
			headers: {
				Authorization: "Bearer " + token,
			},
		});
		const res = response.data;
		res.access_token && setToken(res.access_token);
		setData(res);
	} catch (error) {
		if (error.response) {
			console.log(error.response);
			console.log(error.response.status);
			console.log(error.response.headers);
		}
		if (error.response.status === 403) {
			const res = {
				'access_denied': true
			}
			setData(res)
		}
		else if (error.response.status === 404) {
			const res = {
				'file_not_found': true
			}
			setData(res)
		}
	}
};