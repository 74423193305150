
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';

import { useParams } from 'react-router-dom';
import DT from 'duration-time-conversion';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import Header from '../components/Header';
import Subtitles from '../components/Subtitles';
import Player from '../components/Player';
import Loading from '../components/Loading';
import ProgressBar from '../components/ProgressBar';
import config from "../config.js";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { sub2vtt, sub2srt, sub2txt } from '../libs/readSub';
import { download } from '../utils';
import { fetchData } from "./../libs/fetchData";

import { getKeyCode } from '../utils';
import Sub from '../libs/Sub';

const Style = styled.div`
	height: 100%;
	width: 100%;
	background-color: rgb(0 0 0 / 90%);
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
	line-height: 1.5;
	overflow: hidden;

	h1 {
		color: #878787;
		font-size: 1em;
		position: fixed;
		padding: 10px;
	}

	button.download-subtitle {
		position: fixed;
		background: #515151;
		padding: 10px;
		right: 360px;
		margin: 20px;
		border-radius: 2px;
		border: none;
		color: white;
	}
	button.download-subtitle:hover {
		background: #575757;
	}

	.main {
		display: flex;
		height: 100%;

		.player {
			flex: 1;
			overflow: scroll;
		}

		.subtitles {
			width: 350px;
		}

		.tool {
			width: 300px;
		}
	}

	.footer {
		height: 200px;
	}

	@media only screen and (max-width: 1200px) {
		.main {
			.subtitles {
				display: none;
			}
		}
	}
`;

function Studio(props) {
	const defaultLang = null;
	const subtitleHistory = useRef([]);
	const [player, setPlayer] = useState(null);
	const [loading, setLoading] = useState('');
	const [processing, setProcessing] = useState(0);
	const [language, setLanguage] = useState(defaultLang);
	const [subtitle, setSubtitleOriginal] = useState([]);
	const [waveform, setWaveform] = useState(null);
	const [playing, setPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const [currentIndex, setCurrentIndex] = useState(-1);
	const [subtitleData, setSubtitleData] = useState(null);

	const { id } = useParams();
	const [singleVideoData, setSingleVideoData] = useState(null);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const videoPathFromUrl = urlParams.get('video_path');
		fetchData(props.token, props.setToken, setSingleVideoData, config.apiBaseUrl + "/video/" + id + "?video_path=" + videoPathFromUrl);
	}, [props, id]);


	const newSub = useCallback((item) => new Sub(item), []);
	const hasSub = useCallback((sub) => subtitle.indexOf(sub), [subtitle]);

	const formatSub = useCallback(
		(sub) => {
			if (Array.isArray(sub)) {
				return sub.map((item) => newSub(item));
			}
			return newSub(sub);
		},
		[newSub],
	);

	const copySubs = useCallback(() => formatSub(subtitle), [subtitle, formatSub]);

	const setSubtitle = useCallback(
		(newSubtitle, saveToHistory = true) => {
			if (!isEqual(newSubtitle, subtitle)) {
				if (saveToHistory) {
					if (subtitleHistory.current.length >= 1000) {
						subtitleHistory.current.shift();
					}
					subtitleHistory.current.push(formatSub(subtitle));
				}
				window.localStorage.setItem(getLocalStorageName(), JSON.stringify(newSubtitle));
				setSubtitleOriginal(newSubtitle);
			}
		},
		[subtitle, setSubtitleOriginal, formatSub],
	);

	const getLocalStorageName = () => {
		return 'subtitle' + id;
	}

	const undoSubs = useCallback(() => {
		const subs = subtitleHistory.current.pop();
		if (subs) {
			setSubtitle(subs, false);
		}
	}, [setSubtitle, subtitleHistory]);

	const clearSubs = useCallback(() => {
		setSubtitle([]);
		subtitleHistory.current.length = 0;
	}, [setSubtitle, subtitleHistory]);

	const checkSub = useCallback(
		(sub) => {
			const index = hasSub(sub);
			if (index < 0) return;
			const previous = subtitle[index - 1];
			return (previous && sub.startTime < previous.endTime) || !sub.check || sub.duration < 0.2;
		},
		[subtitle, hasSub],
	);

	const removeSub = useCallback(
		(sub) => {
			const index = hasSub(sub);
			if (index < 0) return;
			const subs = copySubs();
			subs.splice(index, 1);
			setSubtitle(subs);
		},
		[hasSub, copySubs, setSubtitle],
	);

	const addSub = useCallback(
		(index, sub) => {
			const subs = copySubs();
			subs.splice(index, 0, formatSub(sub));
			setSubtitle(subs);
		},
		[copySubs, setSubtitle, formatSub],
	);

	const updateSub = useCallback(
		(sub, obj) => {
			const index = hasSub(sub);
			if (index < 0) return;
			const subs = copySubs();
			const subClone = formatSub(sub);
			Object.assign(subClone, obj);
			if (subClone.check) {
				subs[index] = subClone;
				setSubtitle(subs);
			}
		},
		[hasSub, copySubs, setSubtitle, formatSub],
	);

	const mergeSub = useCallback(
		(sub) => {
			const index = hasSub(sub);
			if (index < 0) return;
			const subs = copySubs();
			const next = subs[index + 1];
			if (!next) return;
			const merge = newSub({
				start: sub.start,
				end: next.end,
				text: sub.text.trim() + '\n' + next.text.trim(),
			});
			subs[index] = merge;
			subs.splice(index + 1, 1);
			setSubtitle(subs);
		},
		[hasSub, copySubs, setSubtitle, newSub],
	);

	const splitSub = useCallback(
		(sub, start) => {
			const index = hasSub(sub);
			if (index < 0 || !sub.text || !start) return;
			const subs = copySubs();
			const text1 = sub.text.slice(0, start).trim();
			const text2 = sub.text.slice(start).trim();
			console.log('text2: ' + text2);
			if (!text1 || !text2) return;
			const splitDuration = (sub.duration * (start / sub.text.length)).toFixed(3);
			if (splitDuration < 0.2 || sub.duration - splitDuration < 0.2) return;
			subs.splice(index, 1);
			const middleTime = DT.d2t(sub.startTime + parseFloat(splitDuration));
			subs.splice(
				index,
				0,
				newSub({
					start: sub.start,
					end: middleTime,
					text: text1,
				}),
			);
			subs.splice(
				index + 1,
				0,
				newSub({
					start: middleTime,
					end: sub.end,
					text: text2,
				}),
			);
			setSubtitle(subs);
		},
		[hasSub, copySubs, setSubtitle, newSub],
	);

	const onKeyDown = useCallback(
		(event) => {
			const keyCode = getKeyCode(event);
			switch (keyCode) {
				case 32:
					event.preventDefault();
					if (player) {
						if (playing) {
							player.pause();
						} else {
							player.play();
						}
					}
					break;
				case 90:
					event.preventDefault();
					if (event.metaKey) {
						undoSubs();
					}
					break;
				default:
					break;
			}
		},
		[player, playing, undoSubs],
	);

	useEffect(() => {
		window.addEventListener('keydown', onKeyDown);
		return () => window.removeEventListener('keydown', onKeyDown);
	}, [onKeyDown]);

	useMemo(() => {
		const currentIndex = subtitle.findIndex((item) => item.startTime <= currentTime && item.endTime > currentTime);
		setCurrentIndex(currentIndex);
	}, [currentTime, subtitle]);

	useEffect(() => {
		if (subtitleData) {
			setSubtitleOriginal(subtitleData.map((item) => new Sub(item)));
		}
	}, [subtitleData]);

	useEffect(() => {
		if (!singleVideoData || !singleVideoData.subtitles) {
			return
		}
		const subtitle_path = singleVideoData.subtitles.no.url
		const localSubtitleString = window.localStorage.getItem(getLocalStorageName());
		const fetchSubtitle = () => fetchData(props.token, props.setToken, setSubtitleData, config.apiBaseUrl + subtitle_path);

		if (localSubtitleString) {
			try {
				const localSubtitle = JSON.parse(localSubtitleString);
				if (localSubtitle.length) {
					setSubtitleOriginal(localSubtitle.map((item) => new Sub(item)));
				} else {
					fetchSubtitle();
				}
			} catch (error) {
				fetchSubtitle();
			}
		} else {
			fetchSubtitle();
		}
	}, [setSubtitleOriginal, singleVideoData]);

	const playerProperties = {
		player,
		setPlayer,
		subtitle,
		setSubtitle,
		waveform,
		setWaveform,
		currentTime,
		setCurrentTime,
		currentIndex,
		setCurrentIndex,
		playing,
		setPlaying,
		language,
		setLanguage,
		loading,
		setLoading,
		setProcessing,
		subtitleHistory,

		newSub,
		hasSub,
		checkSub,
		removeSub,
		addSub,
		undoSubs,
		clearSubs,
		updateSub,
		formatSub,
		mergeSub,
		splitSub,
	};

	const downloadSubtitle = useCallback(
		(type) => {
			let text = '';
			const name = `${Date.now()}.${type}`;
			switch (type) {
				case 'vtt':
					text = sub2vtt(subtitle);
					break;
				case 'srt':
					text = sub2srt(subtitle);
					break;
				case 'txt':
					text = sub2txt(subtitle);
					break;
				case 'json':
					text = JSON.stringify(subtitle);
					break;
				default:
					break;
			}
			const url = URL.createObjectURL(new Blob([text]));
			download(url, name);
		},
		[subtitle],
	);

	return (
		<Style>
			<Header userData={props.userData} />
			{ singleVideoData?.id ? (
				<>
					<h1>{ singleVideoData.title }</h1>
					<Popup
						trigger={
							<button className="download-subtitle">&#9776; Verktøy</button>
						}
						contentStyle={{ padding: '10px', border: '1px solid red', cursor: 'pointer', color: '#0a0a0a' }}
						position="bottom center"
					>
						<div>
							<div onClick={() => downloadSubtitle('srt')}>Last ned som .srt-fil</div>
							<div onClick={() => downloadSubtitle('vtt')}>Last ned som .vtt-fil</div>
							<div onClick={() => downloadSubtitle('txt')}>Last ned som .txt-fil</div>
							<div onClick={() => downloadSubtitle('json')}>Last ned som .json-fil</div>
						</div>
					</Popup>
					<div className="main">
						<Player {...playerProperties} tokenProps={props} singleVideoData={singleVideoData} />
						<Subtitles {...playerProperties} />
					</div>
					{loading ? <Loading loading={loading} /> : null}
					{processing > 0 && processing < 100 ? <ProgressBar processing={processing} /> : null}
				</>
			) : singleVideoData?.access_denied ? (
				<div>Du har ikke tilgang til denne filen.</div>
			) : singleVideoData?.file_not_found ? (
				<div>Filen finnes ikke.</div>
			) : (
				<div>Laster...</div>
			)}
		</Style>
	);
}

export default Studio;
