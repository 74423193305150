import axios from "axios";
import config from "../config.js";

export const postData = async (token, endpoint, values, callback) => {
	const headers = { Authorization: `Bearer ` + token };
	axios.post(config.apiBaseUrl + endpoint, values, { headers })
		.then((response) => {
			console.log("Data posted successfully");
			if (callback && typeof callback === 'function')
			{
				callback();
			}
		})
		.catch((error) => {
			console.error("Failed to post data:", error);
		});
};