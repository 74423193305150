import React, { useState, useEffect, createRef, useCallback, useMemo, memo } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faForward } from '@fortawesome/free-solid-svg-icons'
import { faBackward } from '@fortawesome/free-solid-svg-icons'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faPause } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import { isPlaying } from '../utils';
import axios from 'axios';
import config from "../config.js";

const Style = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	padding: 5% 10%;
	flex-wrap: wrap;

	.video-container {
		position: relative;
	}

	.start-button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 10px 20px;
		background-color: #fff;
		border: none;
		font-size: 16px;
		cursor: pointer;
		z-index: 100;
	}

	.controller_buttons {
		display: flex;
		margin-top: 20px;
		justify-content: center;

		.play-button, .skip-button, .save-button {
			background-color: #3e4758;
			color: white;
			padding: 10px 20px;
			border: none;
			border-radius: 4px;
			font-size: 16px;
			cursor: pointer;
			margin: 10px;
		}
	
		.play-button:hover, .skip-button:hover, .save-button:hover {
			background-color: #2c3545;
		}

		.save-button {
			background-color: #0eb0f7;
		}
		.save-button:hover {
			background-color: #135e7e;
		}
	}

	.video {
		display: flex;
		align-items: center;
		justify-content: center;
		height: auto;
		width: auto;
		position: relative;

		video {
			position: relative;
			z-index: 10;
			outline: none;
			max-height: 100%;
			max-width: 100%;
			width: 100%;
			box-shadow: 0px 5px 25px 5px rgb(0 0 0 / 80%);
			background-color: #000;
			cursor: pointer;
		}

		.subtitle {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: absolute;
			z-index: 20;
			left: 0;
			right: 0;
			bottom: 30%;
			width: 100%;
			padding: 0 20px;
			user-select: none;
			pointer-events: none;

			.operate {
				padding: 5px 15px;
				color: #fff;
				font-size: 13px;
				border-radius: 3px;
				margin-bottom: 5px;
				background-color: rgb(0 0 0 / 75%);
				border: 1px solid rgb(255 255 255 / 20%);
				cursor: pointer;
				pointer-events: all;
			}

			.notice {
				padding: 5px;
				background: white;
				border-radius: 5px;
				font-weight: 300;
				margin-bottom: 5px;
				border: 1px solid #cbcbcb;
				font-size: 0.7em;
				color: #000000;
				
				.text {
					padding-left: 5px;
				}
			}

			.textarea {
				width: 100%;
				outline: none;
				resize: none;
				white-space: nowrap;
				text-align: center;
				line-height: 1.2;
				border: none;
				color: #fff;
				font-size: 1em;
				padding: 5px 10px;
				user-select: all;
				pointer-events: all;
				overflow-x: hidden;
				background-color: rgb(0 0 0 / 50%);
				text-shadow: rgb(0 0 0) 1px 0px 1px, rgb(0 0 0) 0px 1px 1px, rgb(0 0 0) -1px 0px 1px,
					rgb(0 0 0) 0px -1px 1px;

				&.long-text {
					font-size: 1em;
				}

				&.pause {
					background-color: black;
				}
			}
		}
	}

	@media only screen and (max-width: 550px) {
		.video {
			.subtitle {
				padding: initial;
			}
		}
	}

	@media only screen and (max-width: 700px) {
		.video {
			.subtitle {
				.textarea {
					&.long-text {
						font-size: 0.6em;
					}
				}
			}
		}
	}

	@media only screen and (min-width: 0px) {
		.video {
			.subtitle {
				bottom: 70%;
			}
		}
	}
	@media only screen and (min-width: 600px) {
		.video {
			.subtitle {
				bottom: 55%;
			}
		}
	}
	@media only screen and (min-width: 700px) {
		.video {
			.subtitle {
				bottom: 50%;
			}
		}
	}
	@media only screen and (min-width: 800px) {
		.video {
			.subtitle {
				bottom: 45%;
			}

			video {
				min-width: 600px;
			}
		}
	}
	@media only screen and (min-width: 1300px) {
		.video {
			.subtitle {
				bottom: 40%;
			}

			video {
				min-width: 800px;
			}
		}
	}
`;

const VideoWrap = memo(
	({
		setPlayer,
		setCurrentTime,
		setPlaying,
		url,
		singleVideoData,
		tokenProps
	}) => {
		const $video = createRef();
		const [isVideoPlaying, setIsVideoPlaying] = useState(false);
		let last_subtitle_saved = null;

		useEffect(() => {
			setPlayer($video.current);
			(function loop() {
				window.requestAnimationFrame(() => {
					if ($video.current) {
						setPlaying(isPlaying($video.current));
						setCurrentTime($video.current.currentTime || 0);
					}
					loop();
				});
			})();
		}, [setPlayer, setCurrentTime, setPlaying, $video]);

		useEffect(() => {
			function handleKeyDown(event) {
				// Dersom man trykker Shift+mellomrom vil vi pause/starte videoen
				if (event.shiftKey && event.code === 'Space') {
					const video = document.getElementsByTagName('video')[0];
					const event = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: true,
				});
				video.dispatchEvent(event);
				video.focus();
				}
			}
			document.addEventListener('keydown', handleKeyDown);
			return () => {
				document.removeEventListener('keydown', handleKeyDown);
			};
		}, []);

		useEffect(() => {
			last_subtitle_saved = getSubtitleFromLocalStorage();
			setInterval(function() {
				if (subtitleHasUnsavedChanges()) {
					saveChanges();
				}
			  }, 30000);
		}, []);

		useEffect(() => {
			setInterval(function() {
				saveCurrentTimeTolocalStorage();
			  }, 10000);
		}, []);

		useEffect(() => {
			const currentTimeFromLocalStorage = getCurrentTimeFromLocalStorage();
			if (currentTimeFromLocalStorage) {
				$video.current.currentTime = currentTimeFromLocalStorage;
			}
		}, []);

		const saveCurrentTimeTolocalStorage = () => {
			if (!$video.current) {
				return;
			}
			const currentTime = $video.current.currentTime;
			if (currentTime) {
				localStorage.setItem('secondsViewed' + singleVideoData.id, currentTime);
			}
		}

		const getCurrentTimeFromLocalStorage = () => {
			return localStorage.getItem('secondsViewed' + singleVideoData.id);
		}		

		const getSubtitleFromLocalStorage = () => {
			return localStorage.getItem('subtitle' + singleVideoData.id);
		}

		const subtitleHasUnsavedChanges = () => {
			const subtitle = getSubtitleFromLocalStorage();
			if (last_subtitle_saved !== subtitle) {
				last_subtitle_saved = subtitle;
				return true;
			}
			return false;
		} 

		const onClick = useCallback(() => {
			if ($video.current) {
				if (isPlaying($video.current)) {
					$video.current.pause();
				} else {
					$video.current.play();
				}
			}
		}, [$video]);

		const handlePlay = () => {
			if ($video.current) {
				if ($video.current.paused) {
					$video.current.play();
					setIsVideoPlaying(true);
				} else {
					$video.current.pause();
					setIsVideoPlaying(false);
				}
			}
			saveCurrentTimeTolocalStorage();
		};

		const handleSkipForward = () => {
			if ($video.current) {
				$video.current.currentTime += 5;
			}
		};

		const handleSkipBackward = () => {
			if ($video.current) {
				$video.current.currentTime -= 5;
			}
		};

		const saveChanges = (changesIsConfirmed = false) => {
			const subtitle = getSubtitleFromLocalStorage();
			if (!subtitle) {
				return;
			}
			if (changesIsConfirmed) {
				changesIsConfirmed = true;
			}
			else {
				changesIsConfirmed = false;
			}
			const values = {
				id: singleVideoData.id,
				title: singleVideoData.title,
				subtitle: subtitle,
				send_to_integration: changesIsConfirmed
			}
			const headers = { Authorization: `Bearer ` + tokenProps.token };
			axios.post(config.apiBaseUrl + "/front-end/update-subtitle/", values, { headers })
				.then((response) => {
					console.log('Subtitle uploaded')
					if (changesIsConfirmed) {
						window.close();
					}
				})
				.catch((error) => {
					console.error("Failed to upload file:", error);
				});
		};

		const saveAndPublishChanges = () => {
			if (window.confirm('Er du sikker på at du vil lagre endringene og publisere de?')) {
				saveChanges(true);
			}
		};

		return (
			<div>
				<div className="video-container">
					<video controls src={url} ref={$video} controlsList="nofullscreen nodownload" onClick={handlePlay} />
				</div>
				<div className="controller_buttons">
					<button className="skip-button" onClick={handleSkipBackward}>
						<FontAwesomeIcon icon={faBackward} title="Gå 5 sekunder tilbake" />
					</button>
					<button className="play-button" onClick={handlePlay}>
						{isVideoPlaying ?
							<FontAwesomeIcon icon={faPause} title="Stopp avspilling" />
							: <FontAwesomeIcon icon={faPlay} title="Start avspilling" />
						}
					</button>
					<button className="skip-button" onClick={handleSkipForward}>
						<FontAwesomeIcon icon={faForward} title="Gå 5 sekunder frem" />
					</button>
				</div>
				<div className="controller_buttons">
				{ singleVideoData.integration ? 
					<button className="save-button" onClick={saveAndPublishChanges}>Publiser endringene til {singleVideoData.integration}</button>
					: <button className="play-button" onClick={saveChanges}>Lagre</button>
				}
				</div>
			</div>
		);
	},
	() => true,
);

export default function Player(props) {
	const [currentSub, setCurrentSub] = useState(null);
	const [focusing, setFocusing] = useState(false);
	const [inputItemCursor, setInputItemCursor] = useState(0);
	const $player = createRef();

	const { tokenProps } = props;
	const { singleVideoData } = props;

	const [getSubtitleError, setSubtitleError] = useState(null);

	const getCurrentSubtitleClass = () => {
		let subtitle = props.subtitle[props.currentIndex];
		if (subtitle && subtitle.text) {
			if (subtitle.text.length > 70) {
				return 'long-text';
			}
		}
		return '';
	};


	useMemo(() => {
		var current_sub = props.subtitle[props.currentIndex];
		setCurrentSub(current_sub);
		if (current_sub) {
			var text = current_sub['text'];
			checkSubtitleForErrors(text);
		}
	}, [props.subtitle, props.currentIndex]);

	const onChange = useCallback(
		(event) => {
			props.player.pause();
			props.updateSub(currentSub, { text: event.target.value });
			if (event.target.selectionStart) {
				setInputItemCursor(event.target.selectionStart);
			}
		},
		[props, currentSub],
	);

	const onClick = useCallback(
		(event) => {
			props.player.pause();
			if (event.target.selectionStart) {
				setInputItemCursor(event.target.selectionStart);
			}
		},
		[props],
	);

	const onFocus = useCallback((event) => {
		setFocusing(true);
		if (event.target.selectionStart) {
			setInputItemCursor(event.target.selectionStart);
		}
	}, []);

	const onBlur = useCallback(() => {
		setTimeout(() => setFocusing(false), 500);
	}, []);

	const onSplit = useCallback(() => {
		props.splitSub(currentSub, inputItemCursor);
	}, [props, currentSub, inputItemCursor]);

	function checkSubtitleForErrors(text) {
		setSubtitleError();
		const lines = text.split('\n');
		if (lines.length > 2) {
			setSubtitleError('NB: Teksten bør maks være på to linjer');
		}
		lines.forEach(function(line) {
			if (line.length >= 70) {
				setSubtitleError('NB: En av linjene er for lange');
			}
		});
	}
	
	return (
		<>
			<Style className="player">
				{ singleVideoData?.url ? (
					<div className="video" ref={$player}>
						<VideoWrap {...props} tokenProps={tokenProps} url={singleVideoData.url} />
						{props.player && currentSub ? (
							<div className="subtitle">
								{getSubtitleError && (
									<span className="notice">
										<FontAwesomeIcon icon={faInfoCircle} />
										<span className="text">{getSubtitleError}</span>
									</span>
								)}
								<TextareaAutosize
									className={`textarea ${!props.playing ? 'pause' : ''} ${getCurrentSubtitleClass()}`}
									maxRows="2"
									value={currentSub.text}
									onChange={onChange}
									onClick={onClick}
									onFocus={onFocus}
									onBlur={onBlur}
									onKeyDown={onFocus}
									spellCheck={true}
								/>
							</div>
						) : null}
					</div>
				) : (
					<div>Laster...</div>
				)}
			</Style>
		</>
	);
}
