import { Translate } from 'react-i18nify';
import Header from '../components/Header'
import InfoPage from '../components/InfoPage'
import LoggedInUserInfo from '../components/LoggedInUserInfo'
import NoAccess from '../components/NoAccess'
import SimpleFooter from '../components/SimpleFooter'
import useToken from '../components/useToken'

function Prices(props) {
	const { token } = useToken();

	return (
		<>
			<Header token={token} userData={props.userData} />
			{ props.userData.type === "admin" ? (
				<InfoPage>
					<p id="user_info">
						<LoggedInUserInfo userData={props.userData} /><br />
						Vilkårene er sist oppdatert 31. august 2023.
					</p>
					<h1><Translate value="PRICES.TITLE" /></h1>
					<div className="terms_container table_of_contents">
						<h2>Innhold</h2>
						<ul>
							<li><a href="#prices1">1. Generelle prisvilkår</a></li>
							<li><a href="#prices2">2. Undertekster og transkribering</a></li>
							<li><a href="#prices3">3. API-tilgang</a></li>
							<li><a href="#prices4">4. Faktura</a></li>
						</ul>
					</div>
					<div className="terms_container">
						<a href="#/" name="prices1"> </a>
						<h2>1. Generelle prisvilkår</h2>
						<p>Tjenesten faktureres månedlig. All fakturering gjøres etterskuddsvis. Med mindre annet er avtalt, benyttes e-postfaktura.</p>
						<p>Alle oppgitte priser er eks. mva.</p>
						<p>Ved forsinket betaling vil purregebyr bli lagt til i henhold til gjeldende satser. I tillegg vil forsinkelsesrenter bli krevd for forsinkede betalinger, regnet etter gjeldende satser.</p>
					</div>
					<div className="terms_container">
						<a href="#/" name="prices2"> </a>
						<h2>2. Undertekster og transkribering</h2>
						<p>Brukerkontoer er kostnadsfrie. Du betaler bare for hvor mye du bruker tjenesten. Det er derfor ingen oppsigelsestid - slutter du å laste opp filer koster det heller ingenting.</p>
						<p>Prisene kan endres med et varsel på minst 31 dager. Alle prisjusteringer blir publisert på denne nettsiden, og varsles som hovedsak ikke på andre måter. Større prisendringer (over 20 %) varsles per e-post til kontoadministrator.</p>
						<p>Pris gjelder per time opplastet materiale.</p>
						<table className="table table-striped">
							<thead>
								<tr>
									<th>Tjeneste</th>
									<th>Pris per time</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Undertekst (video)</td>
									<td>150 kr</td>
								</tr>
								<tr>
									<td>Transkribering (lydfil)</td>
									<td>120 kr</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="terms_container">
						<a href="#/" name="prices3"> </a>
						<h2>3. API-tilgang</h2>
						<p>Dersom du har behov for å koble til direkte fra en annen nettside via vårt API (Application Programming Interface) tilkommer en utviklingskostnad.</p>
						<table className="table table-striped">
							<thead>
								<tr>
									<th>Tjeneste</th>
									<th>Pris</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Utvikling og oppsett av API (engangskostnad)</td>
									<td>30 000 kr</td>
								</tr>
								<tr>
									<td>Tillegg for opplasting via API (per time)</td>
									<td>+ 20 kr</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="terms_container">
						<a href="#/" name="prices4"> </a>
						<h2>4. Faktura</h2>
						<table className="table table-striped">
							<thead>
								<tr>
									<th>Type</th>
									<th>Pris per faktura</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>E-post</td>
									<td>0 kr</td>
								</tr>
								<tr>
									<td>EHF</td>
									<td>15,88 kr</td>
								</tr>
								<tr>
									<td>Brevpost</td>
									<td>35 kr</td>
								</tr>
							</tbody>
						</table>
					</div>
				</InfoPage>
			) : (
				<NoAccess />
			)}
			<SimpleFooter />
		</>
	);
};

export default Prices;
