import styled from 'styled-components';
import React, { useState, useCallback, useEffect } from 'react';
import { Table } from 'react-virtualized';
import unescape from 'lodash/unescape';
import debounce from 'lodash/debounce';

const Style = styled.div`
	position: relative;
	box-shadow: 0px 5px 25px 5px rgb(0 0 0 / 80%);
	background-color: rgb(0 0 0 / 100%);

	.ReactVirtualized__Table {
		.ReactVirtualized__Table__Grid {
			outline: none;
		}

		.ReactVirtualized__Table__row {
			.item {
				height: 100%;
				padding: 5px;

				.textarea {
					border: none;
					width: 100%;
					height: 100%;
					color: #fff;
					font-size: 0.8em;
					padding: 10px;
					background-color: rgba(255, 255, 255, 0.05);
					border: 1px solid rgba(255, 255, 255, 0.1);
					transition: all 0.2s ease;
					resize: none;
					outline: none;
					overflow-x: hidden;
					white-space: nowrap;

					&.highlight {
						background: #3e4758;
					}

					&.illegal {
						background-color: rgb(123 29 0);
						border: 1px solid rgba(255, 255, 255, 0.3);
					}
				}
			}
		}
	}
`;

export default function Subtitles({ currentIndex, subtitle, checkSub, player, updateSub }) {
	const [height, setHeight] = useState(100);

	const resize = useCallback(() => {
		setHeight(document.body.clientHeight + 50);
	}, [setHeight]);

	useEffect(() => {
		resize();
		if (!resize.init) {
			resize.init = true;
			const debounceResize = debounce(resize, 500);
			window.addEventListener('resize', debounceResize);
		}
	}, [resize]);

	useEffect(() => {
		function handleKeyDown(event) {
			const activeElement = document.activeElement;
			if (activeElement.tagName.toLowerCase() === 'textarea' && event.shiftKey && event.code === 'Space') {
				// Dersom man trykker Shift+mellomrom ønsker vi ikke å legge til mellomrom
				event.preventDefault();
		  }
		}
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	return (
		<Style className="subtitles">
			<Table
				headerHeight={40}
				width={350}
				height={height}
				rowHeight={65}
				scrollToIndex={currentIndex}
				rowCount={subtitle.length}
				rowGetter={({ index }) => subtitle[index]}
				headerRowRenderer={() => null}
				rowRenderer={(props) => {
					return (
						<div
							key={props.key}
							className={props.className}
							style={props.style}
							onClick={() => {
								if (player) {
									player.pause();
									if (player.duration >= props.rowData.startTime) {
										player.currentTime = props.rowData.startTime + 0.001;
									}
								}
							}}
						>
							<div className="item">
								<textarea
									maxLength={200}
									spellCheck={true}
									className={[
										'textarea',
										currentIndex === props.index ? 'highlight' : '',
										checkSub(props.rowData) ? 'illegal' : '',
									]
										.join(' ')
										.trim()}
									value={unescape(props.rowData.text)}
									onChange={(event) => {
										updateSub(props.rowData, {
											text: event.target.value,
										});
									}}
								/>
							</div>
						</div>
					);
				}}
			></Table>
		</Style>
	);
}
