import Header from '../components/Header'
import InfoPage from '../components/InfoPage'
import LoggedInUserInfo from '../components/LoggedInUserInfo'
import VideoList from '../components/VideoList'
import NoAccess from '../components/NoAccess'
import SimpleFooter from '../components/SimpleFooter'
import useToken from '../components/useToken'
import React, { useState, useEffect } from "react";
import { fetchData } from "./../libs/fetchData";
import config from "../config.js";

function Statistics(props) {
	const [videoData, setVideoData] = useState(null);
	const { token } = useToken();
	
	useEffect(() => {
		fetchData(props.token, props.setToken, setVideoData, config.apiBaseUrl + "/video-list");
	}, [props.token, props.setToken]);

	return (
		<>
			<Header token={token} userData={props.userData} />
			{ props.userData.type === "admin" ? (
				<InfoPage>
					{videoData ? (
					<>
						<LoggedInUserInfo userData={props.userData} /><br />
						<VideoList videoData={videoData} isSuperAdmin={true} />
					</>
				) : (
					<p>Laster...</p>
				)}
				</InfoPage>
			) : (
				<NoAccess />
			)}
			<SimpleFooter />
		</>
	);
};

export default Statistics;
