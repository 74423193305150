const en = {
	WELCOME: "Welcome to Supertekst",
	LOADING: 'Loading...',
	SUB_TEXT: 'Subtitle Text',
	OPEN_VIDEO: 'Open Video',
	OPEN_SUB: 'Open Subtitle',
	EXPORT_ASS: 'Export ASS',
	EXPORT_SRT: 'Export SRT',
	EXPORT_VTT: 'Export VTT',
	EXPORT_VIDEO: 'Export Video',
	TRANSLATE: 'Translation',
	TRANSLATING: 'Translation...',
	TRANSLAT_SUCCESS: 'Translation success',
	DECODE_START: 'Start decoding audio',
	DECODE_SUCCESS: 'Decoding audio success',
	DECODE_ERROR: 'Decoding audio failed',
	VIDEO_EXT_ERR: 'Unable to open this video format',
	SUB_EXT_ERR: 'Unable to open this subtitle format',
	MOBILE_TIP: 'Please use computer access',
	HOTKEY_01: 'Space: Play / Pause',
	CLEAR: 'Clear',
	UNDO: 'Undo',
	DELETE: 'Delete Subtitle',
	MERGE: 'Merge Next',
	SPLIT: 'Split Subtitle',
	LOADING_FFMPEG: 'Loading dependency...',
	LOADING_FONT: 'Loading font...',
	LOADING_VIDEO: 'Loading video...',
	LOADING_SUB: 'Loading subtitle...',
	CLEAR_TIP: 'Confirm that all data is cleared',
	BURN_ERROR: 'Burn subtitles failed',
	BURN_START: 'Start burn subtitles',
	BURN_SUCCESS: 'Burn subtitles success',
	OPEN_VIDEO_ERROR: 'Please open the video first',
};

const nb = {
	WELCOME: "Velkommen til Supertekst",
	REPLACE_WORDS: {
		TITLE: "Ordliste",
		DESCRIPTION: "Ordlister brukes for å sikre at navn o.l. alltid har samme skrivemåte. Listene brukes også til å rette opp ord som ofte blir skrevet feil.",
		REVIEW_TITLE: "Filer som er klare for gjennomgang",
		REVIEW_DESCRIPTION: "Disse filene har blitt godkjent, og du må vurdere om noen av ordene bør inngå i de generelle ordlistene."
	},
	PRICES: {
		TITLE: "Priser"
	},
	TERMS: {
		TITLE: "Vilkår"
	},
	LOADING: 'Laster inn...',
	SUB_TEXT: 'Undertekst',
	OPEN_VIDEO: 'Åpne videofil',
	OPEN_SUB: 'Åpne undertekst',
	EXPORT_ASS: 'Eksporter som ASS',
	EXPORT_SRT: 'Eksporter som SRT',
	EXPORT_VTT: 'Eksporter som VTT',
	EXPORT_VIDEO: 'Eksporter video',
	TRANSLATE: 'Oversett',
	TRANSLATING: 'Oversetter...',
	TRANSLAT_SUCCESS: 'Oversettelse fullført',
	DECODE_START: 'Start avkoding av lyd',
	DECODE_SUCCESS: 'Avkoding av lyd vellykket',
	DECODE_ERROR: 'Avkoding av lyd mislyktes',
	VIDEO_EXT_ERR: 'Kan ikke åpne denne videoformatet',
	SUB_EXT_ERR: 'Kan ikke åpne dette undertekstformatet',
	MOBILE_TIP: 'Vennligst bruk en datamaskin for tilgang',
	HOTKEY_TITLE: 'Tips',
	HOTKEY_01: 'Du kan alltid starte og stoppe videoen ved å trykke på Shift+mellomrom samtidig.',
	CLEAR: 'Tøm',
	UNDO: 'Angre',
	DELETE: 'Slett undertekst',
	MERGE: 'Slå sammen neste',
	SPLIT: 'Del undertekst',
	LOADING_FFMPEG: 'Laster avhengighet...',
	LOADING_FONT: 'Laster skrifttype...',
	LOADING_VIDEO: 'Laster video...',
	LOADING_SUB: 'Laster undertekst...',
	CLEAR_TIP: 'Bekreft at all data er slettet',
	BURN_ERROR: 'Brenning av undertekst feilet',
	BURN_START: 'Start brenning av undertekst',
	BURN_SUCCESS: 'Brenning av undertekst vellykket',
	OPEN_VIDEO_ERROR: 'Vennligst åpne videoen først',
};

const i18n = {
	nb,
	en
};

export default i18n;
