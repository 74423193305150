import styled from 'styled-components';

const InfoPage = styled.div`
	max-width: 800px;
	margin: auto;

	h1 {
		font-size: 3em;
	}
	h2 {
		font-size: 1em;
		margin-bottom: 0px;
		font-weight: 700;
	}
	p, li, table {
		max-width: 65ch;
		min-width: initial !important;
	}
	.table_of_contents ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
	.table_of_contents ul li ul li {
		padding-left: 30px;
	}

	.terms_container {
		margin-bottom: 40px;
	}

	#user_info {
		margin-top: 20px;
		font-size: 0.8em;
		max-width: 100%;
	}
`

export default InfoPage;
