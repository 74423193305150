import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { faMicrophoneAlt } from '@fortawesome/free-solid-svg-icons'

function formatTime(seconds) {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;
	return `${hours ? `${hours}:` : ''}${minutes < 10 ? `0${minutes}` : minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
}

function calculateCost(seconds) {
	if (seconds) {
		const hours = seconds/3600;
		const cost = 170 * hours;
		return formatNumber(cost) + ' kr';
	}
	return '-';
}

function getTranscribeEfficiency(length, transcribe_time) {
	return formatNumber(transcribe_time / length);
}

function formatNumber(number) {
	return number.toLocaleString('nb-NO', { minimumFractionDigits: 2, maximumFractionDigits: 2})
}

function VideoList(props) {

	return (
		<table className="table table-striped">
			<thead>
				<tr>
					<th>ID</th>
					<th>Tittel</th>
					<th>Lengde</th>
					<th>Type</th>
					<th>Status</th>
					<th>Kilde</th>
					{ props.isSuperAdmin && (
						<>
							<th>Transkriberingstid</th>
							<th>Kostnad</th>
							<th title="Antall ganger brukeren manuelt har overstyrt Supertekst">Rettinger</th>
						</>
					)}
				</tr>
			</thead>
			<tbody>
				{props.videoData.map((video) => (
					<tr key={video.id}>
						<th scope="row">{video.id}</th>
						<td>
							{video.status === "success" || video.status === "completed" || video.status === "ready_for_review" || video.status === "ready_for_dictionary_review" ? (
								<Link to={`/studio/${video.id}`}>{video.title}</Link>
							) : (
								<span>{video.title}</span>
							)}
						</td>
						<td>
							{video.length ? (
								<div>{formatTime(video.length)}</div>
							) : (
								<div>-</div>
							)}
						</td>
						<td>
							{video.type === "audio" ? (
								<FontAwesomeIcon icon={faMicrophoneAlt} title="Lydopptak" />
							) : video.type === "video" ? (
								<FontAwesomeIcon icon={faVideo} title="Video" />
							) : (
								<div>Ukjent filtype</div>
							)}
						</td>
						<td>
							{video.status === "success" || video.status === "completed" || video.status === "ready_for_dictionary_review" ? (
								<div className="btn btn-success">Fullført</div>
							) : video.status === "ready_for_review" ? (
								<div className="btn btn-info">Klar for gjennomgang</div>
							) : video.status === "in_progress" ? (
								<div className="btn btn-warning">Transkribering pågår</div>
							) : video.status === "waiting_for_transfer" ? (
								<div className="btn btn-danger">I kø</div>
							) : (
								<div className="btn btn-danger">Ukjent</div>
							)}
						</td>
						<td>{video.source}</td>
						{ props.isSuperAdmin && (
							<>
								<td>{formatTime(video.transcribe_time)} ({getTranscribeEfficiency(video.length, video.transcribe_time)})</td>
								<td>{calculateCost(video.length)}</td>
								<td>{video.changes}</td>
							</>
						)}
					</tr>
				))}
			</tbody>
		</table>
	);
}

export default VideoList;
