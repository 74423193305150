import styled from 'styled-components';

const FooterStyle = styled.div`
	text-align: center;
	margin: 50px;
	padding: 30px;
	font-weight: 100;
`

function SimpleFooter() {
	return (
		<FooterStyle>
			<div className="footer">Copyright &copy; Supertekst - (915 829 031)</div>
		</FooterStyle>
	);
}

export default SimpleFooter;
