import { Translate } from 'react-i18nify';
import styled from 'styled-components';
import Header from '../components/Header'
import SimpleFooter from '../components/SimpleFooter'
import WordListTable from '../components/WordListTable'
import useToken from '../components/useToken'

const HomeStyle = styled.div`
	max-width: 800px;
	margin: auto;
`

function ReplaceWords(props) {
	const { token } = useToken();

	return (
		<>
			<Header token={token} userData={props.userData} />
			<HomeStyle>
				<section>
					<h1><Translate value="REPLACE_WORDS.REVIEW_TITLE" /></h1>
					<p><Translate value="REPLACE_WORDS.REVIEW_DESCRIPTION" /></p>
					<WordListTable token={token} type="ready_for_review" />
				</section>
				<section>
					<h1><Translate value="REPLACE_WORDS.TITLE" /></h1>
					<p><Translate value="REPLACE_WORDS.DESCRIPTION" /></p>
					<WordListTable token={token} />
				</section>
			</HomeStyle>
			<SimpleFooter />
		</>
	);
};

export default ReplaceWords;
